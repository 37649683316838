/** @jsx jsx */
import React from 'react'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import DateFormat from '../components/date-formater'
import { jsx } from '@emotion/react'

import {
  latestBlogItem,
  lbiThumb,
  lbiDetails,
} from '../assets/styles/BlogCard.styles'
import LinkButton from './linkButton'

const BlogCard = ({ data, url }) => {

  const exerptText = (texto, limite) => {
    if (texto?.length <= limite) {
      return texto; 
    }
    const textoLimitado = texto?.slice(0, limite);
    return textoLimitado + '...';
  }
  return (
    <div css={latestBlogItem}>
      <div css={lbiThumb}>
        {data?.attributes?.imagem_destaque && (
          <img
            src={url + data?.attributes?.imagem_destaque.data?.attributes.url}
            alt={data?.attributes?.imagem_destaque.data?.attributes.name}
          />
        )}
      </div>
      <div css={lbiDetails}>
        <span className='date'>
          <DateFormat createdDate={data?.attributes?.createdAt} />
        </span>
        <h2>
          <Link to={`/blog/${data?.attributes?.slug}`}>
            {data?.attributes?.titulo}
          </Link>
        </h2>
        <ReactMarkdown className='resume'>
        {exerptText(data?.attributes?.resumo, 160)}
        </ReactMarkdown>
        <p className='author'>
          por: <span>{data?.attributes?.autor}</span>
        </p>
        <LinkButton label='Ver Mais' url={`/blog/${data?.attributes?.slug}`} />
      </div>
    </div>
  )
}

export default BlogCard
