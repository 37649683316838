/** @jsx jsx */
import { css } from "@emotion/react";

export const bannerStyle = css`
@media (max-width: 500px) {
   display: none
}
`

export const relatedPortfolio = css`
   padding-bottom: 90px;
`;